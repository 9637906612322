.footer-container {
	height: 20vh;
	z-index: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	padding: 5rem 0rem;
	position: relative;
}

.footer-text {
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--theme-primary-font-color);
	padding: 0 1rem;
}
