.navbar {
	width: 100%;
	max-width: 1040px;
	background-color: rgb(0, 0, 0);
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	top: 6px;
	height: 45px;
	z-index: 4;
	margin: 0 auto;
}

.nav-container {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 98%;
	height: 45px;
}

.nav-menu {
	display: flex;
	list-style: none;
	text-align: center;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	overflow: hidden;
}

.nav-item {
	display: block;
	height: 45px;
	width: 90px;
}

.nav-links {
	color: var(--theme-cyan);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	height: 100%;
}

.nav-links.active {
	color: var(--theme-bright-pink);
}

.nav-title {
	transform: translateY(-57px);
	transition: all 0.4s ease-in;
	text-transform: uppercase;
	z-index: -999;
}

.material-icons-outlined {
	padding-top: 15px;
	font-size: 25px;
	transform: translateY(0);
	transition: all 0.8s;
}

.nav-item:hover {
	cursor: pointer;
}

.nav-links:hover .nav-title {
	transform: translateY(-23px);
	transition: all 0.4s ease-in;
	text-transform: uppercase;
	z-index: 1;
}

.nav-links:hover .material-icons-outlined {
	transform: translateY(-40px) rotate(20deg);
	transition: all 0.8s;
}

.menu-icon {
	display: none;
}

@media screen and (max-width: 1024px) {
	.navbar {
		background-color: transparent;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		position: absolute;
		left: -100%;
		opacity: 1;
		transition: all 0.6s ease;
		margin-top: -8px;
		top: 2px;
		padding: 55px 0px;
		z-index: 5;
	}

	.nav-item {
		display: block;
		height: 45px;
		width: 50%;
	}

	.nav-links {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.nav-title {
		margin: 10px;
		width: 80%;
		transform: translateY(0px);
	}

	.nav-item .material-icons-outlined {
		width: 20%;
		transform: translateY(0px);
		padding: 0;
		margin: 10px;
	}

	.nav-menu.active {
		background: var(--theme-black);
		left: 0;
		opacity: 1;
		transition: all 0.6s ease;
	}

	.nav-links:hover .nav-title {
		transform: translateY(0px) translateX(-20%);
		transition: all 0.6s ease;
		text-transform: uppercase;
		z-index: 1;
		color: var(--theme-bright-pink);
	}

	.nav-links:hover .material-icons-outlined {
		transform: translateY(0) rotate(0) translateX(50%);
		transition: all 0.8s;
		color: var(--theme-bright-pink);
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 5%);
		font-size: 1.8rem;
		cursor: pointer;
		color: var(--theme-cyan);
		z-index: 6;
	}
}
