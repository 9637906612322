.certach-container {
	height: fit-content;
	width: 97%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
}

.certach-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 0 1rem;
	margin-top: 50px;
}

.certach-title h1 {
	color: var(--theme-cyan);
	font-size: 18px;
	margin-left: 7px;
	text-transform: uppercase;
}

.certach-title .material-icons-outlined {
	padding-top: 0px;
	color: var(--theme-cyan);
	font-size: 24px;
}

.certach-certifications {
	width: 50%;
	height: 100%;
	padding-bottom: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.certach-achievements {
	width: 50%;
	height: 100%;
	padding-bottom: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

@media screen and (max-width: 640px) {
	.certach-container {
		height: fit-content;
		width: 100%;
		margin-top: 0px;
		padding-top: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
		background-color: var(--theme-bg-color);
		object-fit: contain;
	}

	.certach-certifications {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.certach-achievements {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
}
