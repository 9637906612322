.aboutme-container {
	height: 100vh;
	z-index: 1;
	width: 97%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
}

.aboutme-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 0 1rem;
	margin-top: 50px;
}

.aboutme-title h1 {
	color: var(--theme-cyan);
	font-size: 18px;
	margin-left: 7px;
	text-transform: uppercase;
}

.aboutme-title .material-icons-outlined {
	padding-top: 0px;
	color: var(--theme-cyan);
	font-size: 24px;
}

.aboutme-content {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
}

.aboutme-img-container {
	width: 40%;
	height: 80%;
	margin: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.aboutme-img {
	height: 100%;
	width: 70%;
	object-fit: cover;
	object-position: center;
	border-radius: 4px;
}

.aboutme-text {
	width: 60%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	color: var(--theme-primary-font-color);
	font-size: large;
	text-align: justify;
	margin-right: 2.5rem;
}

.aboutme-text-para {
	padding-bottom: 1rem;
}

@media screen and (max-width: 1023px) {
	.aboutme-container {
		height: fit-content;
		z-index: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
		background-color: var(--theme-bg-color);
		object-fit: contain;
	}

	.aboutme-content {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
		background-color: var(--theme-bg-color);
		object-fit: contain;
	}

	.aboutme-img-container {
		width: 60%;
		height: 60%;
		margin: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.aboutme-img {
		height: 100%;
		width: 80%;
		object-fit: cover;
		object-position: center;
		border-radius: 4px;
	}

	.aboutme-text {
		width: 100%;
		height: 40%;
		padding: 0 2.5rem;
		margin-right: 0rem;
	}
}

@media screen and (max-width: 640px) {
	.aboutme-container {
		height: fit-content;
	}

	.aboutme-img-container {
		width: 60%;
		height: 50%;
		margin: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.aboutme-img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 4px;
	}

	.aboutme-text {
		width: 100%;
		height: 50%;
		padding: 0 2rem;
		font-size: medium;
	}
}
