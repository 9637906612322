.theme {
	--theme-dark-pink: #800f49;
	--theme-medium-pink: #d52158;
	--theme-bright-pink: #ed016a;
	--theme-cyan: #05d5ba;
	--theme-orange: #e6873f;
	--theme-dark-orange-yellow: #f8c467;
	--theme-light-orange-yellow: #ffd68e;
	--theme-bg-color: #121212;
	--theme-black: black;
	--theme-primary-font-color: #dddddd;
}
