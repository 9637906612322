.skills-container {
	height: fit-content;
	z-index: 1;
	width: 97%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
}

.skills-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 0px 1rem;
	margin-top: 50px;
}

.skills-title h1 {
	color: var(--theme-cyan);
	font-size: 18px;
	margin-left: 7px;
	text-transform: uppercase;
}

.skills-title .material-icons-outlined {
	padding-top: 0px;
	color: var(--theme-cyan);
	font-size: 24px;
}

.skills-content {
	height: 90%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
}

.skills-text {
	height: 10%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: var(--theme-primary-font-color);
	padding: 0 1rem;
}
