@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Rubik");

body {
	margin: 0;
	font-family: "Rubik";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #121212;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
