.landing-container {
	height: 100vh;
	z-index: 1;
	width: 100%;
	margin-top: -45px;
	padding-top: 2px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
}

.landing-content {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	background-color: var(--theme-bg-color);
	object-fit: contain;
}

.landing-text {
	width: 100%;
	height: 100%;
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	color: var(--theme-primary-font-color);
}

.small-heading {
	color: white;
	font-size: 1.5rem;
	padding-bottom: 1.5rem;
	font-family: Arial, Helvetica, sans-serif;
}

.big-heading {
	color: var(--theme-cyan);
	font-size: 5rem;
	line-height: 5rem;
	padding-bottom: 1.5rem;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
}

.code {
	position: absolute;
	width: max-content;
	font-family: "Courier New", Courier, monospace;
	font-size: medium;
	line-height: 2rem;
	margin-top: 0.2rem;
}

.social-media {
	position: absolute;
	margin-top: 19rem;
	width: 8.5rem;
}

.heading {
	position: absolute;
	margin-top: -10rem;
}

@media screen and (max-width: 1023px) {
	.landing-container {
		height: 100vh;
		width: 100%;
		z-index: 1;
		padding-top: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
		background-color: var(--theme-bg-color);
		object-fit: contain;
	}

	.landing-content {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
		background-color: var(--theme-bg-color);
		object-fit: contain;
	}

	.landing-text {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
	}

	.small-heading {
		text-align: center;
		font-size: 1.2rem;
		padding-bottom: 1.5rem;
	}

	.big-heading {
		font-size: 4.5rem;
		text-align: center;
	}
	.code {
		position: relative;
		margin-top: 0;
	}
	.heading {
		position: relative;
		margin-top: 0;
	}
	.social-media {
		position: relative;
		margin-top: 0;
	}
}

@media screen and (max-width: 640px) {
	.landing-text {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0rem;
		position: absolute;
	}

	.landing-img {
		height: 100%;
		width: 100%;
	}

	.small-heading {
		font-size: 1rem;
	}

	.big-heading {
		font-size: 2.8rem;
		line-height: 2.8rem;
	}

	.code {
		font-size: small;
		line-height: 1.5rem;
	}
}

@media screen and (max-width: 375px) {
	.big-heading {
		font-size: 2.6rem;
		line-height: 2.6rem;
	}
}
